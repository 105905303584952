"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageTrackerLoader = void 0;
/* eslint-disable class-methods-use-this */
const three_1 = require("../three");
const defaultpipeline_1 = require("../defaultpipeline");
/**
 * Loader for image trackers.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/image-tracking/
 */
class ImageTrackerLoader extends three_1.THREE.Loader {
    /**
     * Loads an image tracker.
     * @param zpt - A URL to, or ArrayBuffer of, an image target file.
     * @param onLoad - Callback which returns the imageTracker once it's loaded.
     * @param onError - Callback which is called if there's an error loading the target file.
     * @returns The ImageTracker.
     * @see https://docs.zap.works/universal-ar/zapworks-cli/
     */
    load(zpt, onLoad, onProgress, onError) {
        const trk = new defaultpipeline_1.ImageTracker();
        trk
            .loadTarget(zpt)
            .then(() => {
            onLoad === null || onLoad === void 0 ? void 0 : onLoad(trk);
            this.manager.itemEnd(zpt);
        })
            .catch((_) => {
            onError === null || onError === void 0 ? void 0 : onError(_);
            this.manager.itemError(zpt);
            this.manager.itemEnd(zpt);
        });
        return trk;
    }
    /**
     * @ignore
     */
    parse() { }
}
exports.ImageTrackerLoader = ImageTrackerLoader;
