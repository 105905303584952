"use strict";
/* eslint-disable guard-for-in */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadingManager = exports.DefaultLoaderUI = void 0;
const three_1 = require("../three");
const libraryloader_1 = require("./libraryloader");
class UI {
    constructor(style) {
        this.lastLoadPercentage = 0;
        this.divs = {
            inner: document.createElement("div"),
            title: document.createElement("div"),
            progress: document.createElement("div"),
            progressValue: document.createElement("div"),
        };
        this.css = {
            container: {
                position: "fixed",
                width: "100%",
                height: "100%",
                top: "0px",
                left: "0px",
                zIndex: "10000",
                backgroundColor: "rgba(0,0,0,0.8)",
                fontFamily: "sans-serif",
                color: "rgba(255,255,255,1)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                transition: "opacity 500ms",
            },
            inner: {
                maxWidth: "400px",
                textAlign: "center",
            },
            title: {
                fontSize: "20px",
            },
            progress: {
                background: "rgba(255,255,255, 0.1)",
                justifyContent: "flex-start",
                borderRadius: "100px",
                alignItems: "center",
                position: "relative",
                padding: "0 5px",
                display: "flex",
                height: "15px",
                width: "250px",
                margin: "15px",
            },
            progressValue: {
                boxShadow: "0 10px 40px -10px #fff",
                borderTopLeftRadius: "100px",
                borderBottomLeftRadius: "100px",
                background: "rgba(255,255,255,1)",
                height: "10px",
                width: "0",
                transition: "width 500ms",
            },
        };
        // update loading bar based on % = (n-loaded / n-total * 100)
        this.updateLoader = (loadPercentage) => {
            if (loadPercentage < this.lastLoadPercentage)
                return;
            this.lastLoadPercentage = loadPercentage;
            const elem = document.getElementById("zappar-loader-progressValue");
            elem.style.width = `${loadPercentage}%`;
            elem.style.borderTopRightRadius = `${loadPercentage}px`;
            elem.style.borderBottomRightRadius = `${loadPercentage}px`;
            elem.addEventListener("transitionend", () => {
                if (loadPercentage === 100) {
                    this.containerDiv.style.opacity = "0";
                    this.containerDiv.addEventListener("transitionend", (ev) => {
                        if (ev.propertyName === "opacity") {
                            this.dispose();
                        }
                    });
                }
            });
        };
        this.dispose = () => {
            this.containerDiv.remove();
        };
        this.customStyle = style;
        this.containerDiv = document.createElement("div");
    }
    initialize() {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in this.divs) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.divs[key].id = `zappar-loader-${key}`;
        }
        this.refreshStyle();
        this.divs.inner.appendChild(this.divs.title);
        this.divs.inner.appendChild(this.divs.progress);
        this.divs.progress.appendChild(this.divs.progressValue);
        this.containerDiv.appendChild(this.divs.inner);
        this.divs.title.innerHTML = "Loading..";
        document.body.append(this.containerDiv);
    }
    refreshStyle() {
        var _a, _b, _c, _d, _e;
        Object.assign(this.css.container, (_a = this.customStyle) === null || _a === void 0 ? void 0 : _a.container);
        Object.assign(this.css.inner, (_b = this.customStyle) === null || _b === void 0 ? void 0 : _b.inner);
        Object.assign(this.css.title, (_c = this.customStyle) === null || _c === void 0 ? void 0 : _c.title);
        Object.assign(this.css.progress, (_d = this.customStyle) === null || _d === void 0 ? void 0 : _d.progress);
        Object.assign(this.css.progressValue, (_e = this.customStyle) === null || _e === void 0 ? void 0 : _e.progressValue);
        Object.assign(this.containerDiv.style, this.css.container);
        Object.assign(this.divs.inner.style, this.css.inner);
        Object.assign(this.divs.title.style, this.css.title);
        Object.assign(this.divs.progress.style, this.css.progress);
        Object.assign(this.divs.progressValue.style, this.css.progressValue);
    }
}
/**
 * Creates a THREE.DefaultLoadingManager which is applied to all assets that can be loaded.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/loading-manager/
 */
class DefaultLoaderUI extends UI {
    /**
     * Constructs a new DefaultLoaderUI.
     * @param options - The styling of the UI.
     * @param onload - The callback function to be called when assets are loaded.
     */
    constructor(options) {
        super(options === null || options === void 0 ? void 0 : options.style);
        three_1.THREE.DefaultLoadingManager.onStart = () => {
            this.initialize();
        };
        three_1.THREE.DefaultLoadingManager.onLoad = () => {
            var _a;
            (_a = options === null || options === void 0 ? void 0 : options.onLoad) === null || _a === void 0 ? void 0 : _a.call(options);
        };
        three_1.THREE.DefaultLoadingManager.onProgress = (_url, itemsLoaded, itemsTotal) => {
            this.updateLoader((itemsLoaded / itemsTotal) * 100);
        };
    }
}
exports.DefaultLoaderUI = DefaultLoaderUI;
/**
 * A LoadingManager with a user friendly interface.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/loading-manager/
 */
class LoadingManager extends three_1.THREE.LoadingManager {
    /**
     * Constructs a new LoadingManager.
     * @param options - Styling may be defined here, as well as any event callbacks.
     */
    constructor(options) {
        super(() => { var _a; return (_a = options === null || options === void 0 ? void 0 : options.onLoad) === null || _a === void 0 ? void 0 : _a.call(options); }, (url, loaded, total) => {
            var _a;
            this.ui.customStyle = options === null || options === void 0 ? void 0 : options.style;
            this.ui.refreshStyle();
            (_a = options === null || options === void 0 ? void 0 : options.onProgress) === null || _a === void 0 ? void 0 : _a.call(options, url, loaded, total);
            this.ui.updateLoader((loaded / total) * 100);
        }, options === null || options === void 0 ? void 0 : options.onError);
        this.ui = new UI(); // no multiple inheritance (╯°□°)╯︵ ┻━┻
        this.onStartCallback = undefined;
        /**
         * @ignore
         */
        this.onStart = () => {
            this.ui.initialize();
            if (this.onStartCallback)
                this.onStartCallback();
        };
        /**
         * Calls provided function when loading begins.
         * @param callback - Function that is called when loading starts.
         */
        this._onStart = (callback) => {
            this.onStartCallback = callback;
        };
        /**
         * Destroys the UI.
         */
        this.dispose = () => {
            this.ui.dispose();
        };
        new libraryloader_1.LibraryLoader(this).load();
    }
}
exports.LoadingManager = LoadingManager;
