"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaceBufferGeometry = void 0;
const three_1 = require("../three");
const facemeshloader_1 = require("../loaders/facemeshloader");
let faceMeshSingleton;
/**
 * A THREE.BufferGeometry that fits to the user's face and deforms as the user's expression changes.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/face-tracking/
 */
class FaceBufferGeometry extends three_1.THREE.BufferGeometry {
    /**
     * Constructs a new FaceBufferGeometry.
     * @param faceMesh - The face mesh which will be used. If not specified, the default face mesh will be loaded.
     */
    constructor(faceMesh) {
        super();
        this.hasSetIndices = false;
        this.hasSetUVs = false;
        this.recalculateNormals = true;
        this.setIndex([]);
        this.setAttribute("position", new three_1.THREE.Float32BufferAttribute([], 3));
        this.setAttribute("normal", new three_1.THREE.Float32BufferAttribute([], 3));
        this.setAttribute("uv", new three_1.THREE.Float32BufferAttribute([], 2));
        if (!faceMesh) {
            if (!faceMeshSingleton) {
                faceMeshSingleton = new facemeshloader_1.FaceMeshLoader().load();
            }
            // eslint-disable-next-line no-param-reassign
            faceMesh = faceMeshSingleton;
        }
        this._faceMesh = faceMesh;
    }
    _updateIndices() {
        if (this.hasSetIndices)
            return;
        if (this._faceMesh.indices.length === 0)
            return;
        this.setIndex(new three_1.THREE.Uint16BufferAttribute(this._faceMesh.indices, 1));
        this.hasSetIndices = true;
    }
    _updateUVs() {
        if (this.hasSetUVs)
            return;
        if (this._faceMesh.uvs.length === 0)
            return;
        this.setAttribute("uv", new three_1.THREE.BufferAttribute(this._faceMesh.uvs, 2));
        this.hasSetUVs = true;
    }
    /**
     * @ignore
     */
    get calculateNormals() {
        return this.recalculateNormals;
    }
    /**
     * @ignore
     */
    set calculateNormals(b) {
        this.recalculateNormals = b;
        if (!this.recalculateNormals) {
            if (typeof this.removeAttribute === "function") {
                this.removeAttribute("normal");
            }
            delete this.normals;
        }
    }
    /**
     * Updates the geometry to the most recent identity and expression output from a face anchor group.
     * @param f - The face anchor group which will be used to update the geometry.
     */
    updateFromFaceAnchorGroup(f) {
        if (this._faceMesh.vertices.length === 0)
            return;
        if (!f.currentAnchor)
            return;
        this.updateFromFaceAnchor(f.currentAnchor);
    }
    /**
     * Updates the geometry to the most recent identity and expression output from a face anchor.
     * @param f - The face anchor which will be used to update the geometry.
     */
    updateFromFaceAnchor(f) {
        this.updateFromIdentityExpression(f.identity, f.expression);
    }
    /**
     * Updates the geometry to the provided identity and expression coefficients.
     * @param identity  - The identity coefficients.
     * @param expression - The expression coefficients.
     */
    updateFromIdentityExpression(identity, expression) {
        if (this._faceMesh.vertices.length === 0)
            return;
        this._updateIndices();
        this._updateUVs();
        this._faceMesh.updateFromIdentityExpression(identity, expression);
        if (!this.vertices) {
            this.vertices = new Float32Array(this._faceMesh.vertices.length);
            this.verticesAttribute = new three_1.THREE.BufferAttribute(this.vertices, 3);
            this.setAttribute("position", this.verticesAttribute);
        }
        this.vertices.set(this._faceMesh.vertices);
        if (this.verticesAttribute)
            this.verticesAttribute.needsUpdate = true;
        this.computeBoundingSphere();
        if (!this.calculateNormals)
            return;
        if (!this.normals) {
            this.normals = new Float32Array(this._faceMesh.normals.length);
            this.normalsAttribute = new three_1.THREE.BufferAttribute(this.normals, 3);
            this.setAttribute("normal", this.normalsAttribute);
        }
        this.normals.set(this._faceMesh.normals);
        if (this.normalsAttribute)
            this.normalsAttribute.needsUpdate = true;
    }
}
exports.FaceBufferGeometry = FaceBufferGeometry;
