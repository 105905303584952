"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SequenceSource = exports.loadedPromise = exports.loaded = exports.browserIncompatibleUI = exports.browserIncompatible = exports.permissionRequestUI = exports.permissionDeniedUI = exports.permissionRequest = exports.permissionGranted = exports.permissionDenied = exports.setLogLevel = exports.LogLevel = exports.Pipeline = exports.FaceLandmarkName = exports.FaceLandmark = exports.FaceMesh = exports.getDefaultPipeline = exports.glContextLost = exports.glContextSet = exports.onFrameUpdate = exports.HTMLElementSource = exports.CameraSource = exports.FaceTracker = exports.BarcodeFinder = exports.InstantWorldTracker = exports.ImageTracker = exports.TargetImagePreviewMesh = exports.TargetImagePreviewBufferGeometry = exports.CameraEnvironmentMap = exports.DefaultLoaderUI = exports.LoadingManager = exports.LibraryLoader = exports.HeadMaskMeshLoader = exports.HeadMaskMesh = exports.FaceMeshLoader = exports.FaceBufferGeometry = exports.FaceTrackerLoader = exports.FaceLandmarkGroup = exports.FaceAnchorGroup = exports.InstantWorldAnchorGroup = exports.ImageTrackerLoader = exports.ImageAnchorGroup = exports.CameraMirrorMode = exports.CameraPoseMode = exports.Camera = void 0;
const version_1 = require("./version");
console.log(`Zappar for ThreeJS v${version_1.VERSION}`);
var camera_1 = require("./camera");
Object.defineProperty(exports, "Camera", { enumerable: true, get: function () { return camera_1.Camera; } });
Object.defineProperty(exports, "CameraPoseMode", { enumerable: true, get: function () { return camera_1.CameraPoseMode; } });
Object.defineProperty(exports, "CameraMirrorMode", { enumerable: true, get: function () { return camera_1.CameraMirrorMode; } });
var imageanchorgroup_1 = require("./trackers/imageanchorgroup");
Object.defineProperty(exports, "ImageAnchorGroup", { enumerable: true, get: function () { return imageanchorgroup_1.ImageAnchorGroup; } });
var imagetrackerloader_1 = require("./loaders/imagetrackerloader");
Object.defineProperty(exports, "ImageTrackerLoader", { enumerable: true, get: function () { return imagetrackerloader_1.ImageTrackerLoader; } });
var instantworldanchorgroup_1 = require("./trackers/instantworldanchorgroup");
Object.defineProperty(exports, "InstantWorldAnchorGroup", { enumerable: true, get: function () { return instantworldanchorgroup_1.InstantWorldAnchorGroup; } });
var faceanchorgroup_1 = require("./trackers/faceanchorgroup");
Object.defineProperty(exports, "FaceAnchorGroup", { enumerable: true, get: function () { return faceanchorgroup_1.FaceAnchorGroup; } });
var facelandmarkgroup_1 = require("./trackers/facelandmarkgroup");
Object.defineProperty(exports, "FaceLandmarkGroup", { enumerable: true, get: function () { return facelandmarkgroup_1.FaceLandmarkGroup; } });
var facetrackerloader_1 = require("./loaders/facetrackerloader");
Object.defineProperty(exports, "FaceTrackerLoader", { enumerable: true, get: function () { return facetrackerloader_1.FaceTrackerLoader; } });
var facebuffergeometry_1 = require("./geometry/facebuffergeometry");
Object.defineProperty(exports, "FaceBufferGeometry", { enumerable: true, get: function () { return facebuffergeometry_1.FaceBufferGeometry; } });
var facemeshloader_1 = require("./loaders/facemeshloader");
Object.defineProperty(exports, "FaceMeshLoader", { enumerable: true, get: function () { return facemeshloader_1.FaceMeshLoader; } });
var headmaskmesh_1 = require("./mesh/headmaskmesh");
Object.defineProperty(exports, "HeadMaskMesh", { enumerable: true, get: function () { return headmaskmesh_1.HeadMaskMesh; } });
var headmaskmeshloader_1 = require("./loaders/headmaskmeshloader");
Object.defineProperty(exports, "HeadMaskMeshLoader", { enumerable: true, get: function () { return headmaskmeshloader_1.HeadMaskMeshLoader; } });
var libraryloader_1 = require("./loaders/libraryloader");
Object.defineProperty(exports, "LibraryLoader", { enumerable: true, get: function () { return libraryloader_1.LibraryLoader; } });
var loadingmanager_1 = require("./loaders/loadingmanager");
Object.defineProperty(exports, "LoadingManager", { enumerable: true, get: function () { return loadingmanager_1.LoadingManager; } });
Object.defineProperty(exports, "DefaultLoaderUI", { enumerable: true, get: function () { return loadingmanager_1.DefaultLoaderUI; } });
var cameraenvironmentmap_1 = require("./cameraenvironmentmap");
Object.defineProperty(exports, "CameraEnvironmentMap", { enumerable: true, get: function () { return cameraenvironmentmap_1.CameraEnvironmentMap; } });
var targetimagepreviewbuffergeometry_1 = require("./geometry/targetimagepreviewbuffergeometry");
Object.defineProperty(exports, "TargetImagePreviewBufferGeometry", { enumerable: true, get: function () { return targetimagepreviewbuffergeometry_1.TargetImagePreviewBufferGeometry; } });
var targetimagepreviewmesh_1 = require("./mesh/targetimagepreviewmesh");
Object.defineProperty(exports, "TargetImagePreviewMesh", { enumerable: true, get: function () { return targetimagepreviewmesh_1.TargetImagePreviewMesh; } });
var defaultpipeline_1 = require("./defaultpipeline");
Object.defineProperty(exports, "ImageTracker", { enumerable: true, get: function () { return defaultpipeline_1.ImageTracker; } });
Object.defineProperty(exports, "InstantWorldTracker", { enumerable: true, get: function () { return defaultpipeline_1.InstantWorldTracker; } });
Object.defineProperty(exports, "BarcodeFinder", { enumerable: true, get: function () { return defaultpipeline_1.BarcodeFinder; } });
Object.defineProperty(exports, "FaceTracker", { enumerable: true, get: function () { return defaultpipeline_1.FaceTracker; } });
Object.defineProperty(exports, "CameraSource", { enumerable: true, get: function () { return defaultpipeline_1.CameraSource; } });
Object.defineProperty(exports, "HTMLElementSource", { enumerable: true, get: function () { return defaultpipeline_1.HTMLElementSource; } });
Object.defineProperty(exports, "onFrameUpdate", { enumerable: true, get: function () { return defaultpipeline_1.onFrameUpdate; } });
Object.defineProperty(exports, "glContextSet", { enumerable: true, get: function () { return defaultpipeline_1.glContextSet; } });
Object.defineProperty(exports, "glContextLost", { enumerable: true, get: function () { return defaultpipeline_1.glContextLost; } });
Object.defineProperty(exports, "getDefaultPipeline", { enumerable: true, get: function () { return defaultpipeline_1.getDefaultPipeline; } });
var zappar_1 = require("@zappar/zappar");
Object.defineProperty(exports, "FaceMesh", { enumerable: true, get: function () { return zappar_1.FaceMesh; } });
Object.defineProperty(exports, "FaceLandmark", { enumerable: true, get: function () { return zappar_1.FaceLandmark; } });
Object.defineProperty(exports, "FaceLandmarkName", { enumerable: true, get: function () { return zappar_1.FaceLandmarkName; } });
Object.defineProperty(exports, "Pipeline", { enumerable: true, get: function () { return zappar_1.Pipeline; } });
Object.defineProperty(exports, "LogLevel", { enumerable: true, get: function () { return zappar_1.LogLevel; } });
Object.defineProperty(exports, "setLogLevel", { enumerable: true, get: function () { return zappar_1.setLogLevel; } });
Object.defineProperty(exports, "permissionDenied", { enumerable: true, get: function () { return zappar_1.permissionDenied; } });
Object.defineProperty(exports, "permissionGranted", { enumerable: true, get: function () { return zappar_1.permissionGranted; } });
Object.defineProperty(exports, "permissionRequest", { enumerable: true, get: function () { return zappar_1.permissionRequest; } });
Object.defineProperty(exports, "permissionDeniedUI", { enumerable: true, get: function () { return zappar_1.permissionDeniedUI; } });
Object.defineProperty(exports, "permissionRequestUI", { enumerable: true, get: function () { return zappar_1.permissionRequestUI; } });
Object.defineProperty(exports, "browserIncompatible", { enumerable: true, get: function () { return zappar_1.browserIncompatible; } });
Object.defineProperty(exports, "browserIncompatibleUI", { enumerable: true, get: function () { return zappar_1.browserIncompatibleUI; } });
Object.defineProperty(exports, "loaded", { enumerable: true, get: function () { return zappar_1.loaded; } });
Object.defineProperty(exports, "loadedPromise", { enumerable: true, get: function () { return zappar_1.loadedPromise; } });
Object.defineProperty(exports, "SequenceSource", { enumerable: true, get: function () { return zappar_1.SequenceSource; } });
