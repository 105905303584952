"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TargetImagePreviewMesh = void 0;
const __1 = require("..");
const three_1 = require("../three");
/**
 * A THREE.Mesh that fits the target image.
 * If a material is not specified, it will use a default THREE.MeshBasicMaterial with a map of the target image.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/image-tracking/
 */
class TargetImagePreviewMesh extends three_1.THREE.Mesh {
    constructor(target, material = new three_1.THREE.MeshBasicMaterial({
        map: new three_1.THREE.TextureLoader().load(target.image.src),
    })) {
        super(new __1.TargetImagePreviewBufferGeometry(target), material);
    }
}
exports.TargetImagePreviewMesh = TargetImagePreviewMesh;
