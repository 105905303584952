"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TargetImagePreviewBufferGeometry = void 0;
const three_1 = require("../three");
/**
 * A THREE.BufferGeometry that fits to the target image.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/image-tracking/
 */
class TargetImagePreviewBufferGeometry extends three_1.THREE.BufferGeometry {
    /**
     * Constructs a new TargetImagePreviewBufferGeometry.
     * @param imageTarget - The image target which will be used.
     */
    constructor(imageTarget) {
        var _a;
        super();
        this.imageTarget = imageTarget;
        this.hasSetIndices = false;
        this.hasSetUVs = false;
        this.recalculateNormals = true;
        this.setIndex([]);
        this.setAttribute("position", new three_1.THREE.Float32BufferAttribute([], 3));
        this.setAttribute("normal", new three_1.THREE.Float32BufferAttribute([], 3));
        this.setAttribute("uv", new three_1.THREE.Float32BufferAttribute([], 2));
        if (this.imageTarget.preview.vertices.length === 0) {
            throw new Error("No vertices found in the image target.");
        }
        this._updateIndices();
        this._updateUVs();
        if (!this.vertices) {
            this.vertices = new Float32Array((_a = this.imageTarget) === null || _a === void 0 ? void 0 : _a.preview.vertices.length);
            this.verticesAttribute = new three_1.THREE.BufferAttribute(this.vertices, 3);
            this.setAttribute("position", this.verticesAttribute);
        }
        this.vertices.set(this.imageTarget.preview.vertices);
        if (this.verticesAttribute)
            this.verticesAttribute.needsUpdate = true;
        this.computeBoundingSphere();
    }
    /**
     * @ignore
     */
    _updateIndices() {
        var _a;
        if (this.hasSetIndices)
            return;
        if (((_a = this.imageTarget) === null || _a === void 0 ? void 0 : _a.preview.indices.length) === 0)
            return;
        this.setIndex(new three_1.THREE.Uint16BufferAttribute(this.imageTarget.preview.indices, 1));
        this.hasSetIndices = true;
    }
    /**
     * @ignore
     */
    _updateUVs() {
        if (this.hasSetUVs)
            return;
        if (this.imageTarget.preview.uvs.length === 0)
            return;
        this.setAttribute("uv", new three_1.THREE.BufferAttribute(this.imageTarget.preview.uvs, 2));
        this.hasSetUVs = true;
    }
    /**
     * @ignore
     */
    get calculateNormals() {
        return this.recalculateNormals;
    }
}
exports.TargetImagePreviewBufferGeometry = TargetImagePreviewBufferGeometry;
