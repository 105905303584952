"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraEnvironmentMap = void 0;
const three_1 = require("./three");
class CameraEnvironmentMap {
    /**
     * Constructs a new Camera Environment Map.
     */
    constructor() {
        this.cubeMapScene = new three_1.THREE.Scene();
        this.renderTarget = new three_1.THREE.WebGLCubeRenderTarget(256, {
            format: three_1.THREE.RGBAFormat,
            generateMipmaps: true,
            minFilter: three_1.THREE.LinearMipmapLinearFilter,
        });
        this.cubeCamera = new three_1.THREE.CubeCamera(0.1, 1000, this.renderTarget);
        this.sphereMaterial = new three_1.THREE.MeshBasicMaterial({ side: three_1.THREE.DoubleSide });
        this.sphereGroup = new three_1.THREE.Group();
        /**
         * The resulting map texture. Set this as your `scene.environment` or as a material's `envMap`.
         */
        this.environmentMap = this.renderTarget.texture;
        this.cubeMapScene.add(this.cubeCamera);
        const sphere = new three_1.THREE.Mesh(new three_1.THREE.SphereBufferGeometry(10, 16, 12), this.sphereMaterial);
        sphere.rotation.set(0, -0.5 * Math.PI, 0);
        this.sphereGroup.add(sphere);
        this.cubeMapScene.add(this.sphereGroup);
    }
    /**
     * Destroy the resources held by this object.
     */
    dispose() {
        this.renderTarget.dispose();
        this.sphereMaterial.dispose();
    }
    /**
     * Update the contents of the environment map with the latest texture from the camera.
     *
     * Call this each frame after you call `update` on your Zappar camera, but before you render the scene.
     * @param renderer - Your renderer object
     * @param zapparCamera - The Zappar camera you're using to render your scene
     */
    update(renderer, zapparCamera) {
        this.environmentMap.encoding = renderer.outputEncoding;
        this.sphereMaterial.map = zapparCamera.backgroundTexture;
        this.sphereGroup.quaternion.copy(zapparCamera.quaternion);
        this.cubeCamera.update(renderer, this.cubeMapScene);
    }
}
exports.CameraEnvironmentMap = CameraEnvironmentMap;
