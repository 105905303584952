"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeadMaskMesh = void 0;
const Zappar = require("@zappar/zappar");
const three_1 = require("../three");
const facebuffergeometry_1 = require("../geometry/facebuffergeometry");
/**
 * A THREE.Mesh that fits the user's head and fills the depth buffer,
 * ensuring that the camera image of the head shows instead of any 3D elements behind it in the scene.
 *
 * Works using a full-head ZapparThree.FaceMesh with the mouth, eyes and neck filled in.
 * Its renderOrder is set to Number.MIN_SAFE_INTEGER to ensure it's rendered before any other objects in the scene,
 * and its material has the colorWrite property set to false so it fills the depth buffer but not the color buffer.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/face-tracking/
 */
class HeadMaskMesh extends three_1.THREE.Mesh {
    /**
     * Constructs a new head mask mesh.
     * @param onLoad - Callback function which runs when the mesh is loaded.
     * @param onError - Callback which is called if there's an error loading the mesh.
     */
    constructor(onLoad, onError) {
        super();
        this.onLoad = onLoad;
        this.onError = onError;
        this.faceMesh = new Zappar.FaceMesh();
        this.faceBufferGeometry = new facebuffergeometry_1.FaceBufferGeometry(this.faceMesh);
        this.geometry = this.faceBufferGeometry;
        this.material = new three_1.THREE.MeshBasicMaterial({
            colorWrite: false,
        });
        this.faceMesh
            .loadDefaultFullHeadSimplified(true, true, true, true)
            .then(() => { var _a; return (_a = this.onLoad) === null || _a === void 0 ? void 0 : _a.call(this); })
            .catch(() => { var _a; return (_a = this.onError) === null || _a === void 0 ? void 0 : _a.call(this); });
        this.renderOrder = Number.MIN_SAFE_INTEGER;
    }
    /**
     * Updates pose directly from the [[FaceAnchorGroup]] anchor.
     * @param f - The anchor to derive the expression and identity from.
     */
    updateFromFaceAnchorGroup(f) {
        this.faceBufferGeometry.updateFromFaceAnchorGroup(f);
    }
    /**
     * Updates pose directly from the expression and identity in a [[FaceAnchor]].
     * @param f - The anchor to derive the expression and identity from.
     */
    updateFromFaceAnchor(f) {
        this.faceBufferGeometry.updateFromFaceAnchor(f);
    }
    /**
     * Updates pose directly from identity and expression coefficients.
     * @param identity  - The identity coefficients.
     * @param expression - The expression coefficients.
     */
    updateFromIdentityExpression(identity, expression) {
        this.faceBufferGeometry.updateFromIdentityExpression(identity, expression);
    }
    /**
     * Destroys the face mesh and disposes of resources.
     */
    dispose() {
        this.faceMesh.destroy();
        this.faceBufferGeometry.dispose();
    }
}
exports.HeadMaskMesh = HeadMaskMesh;
