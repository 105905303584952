"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaceTrackerLoader = void 0;
/* eslint-disable class-methods-use-this */
const three_1 = require("../three");
const defaultpipeline_1 = require("../defaultpipeline");
/**
 * Loader for face tracking model data.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/face-tracking/
 */
class FaceTrackerLoader extends three_1.THREE.Loader {
    /**
     * Loads face tracking model data.
     * @param customModel - A URL to, or ArrayBuffer of, model data.
     * @param options - A URL or ArrayBuffer of the source mesh data or defines if some face features should be filled with polygons.
     * @param onLoad - Callback which returns the FaceMesh once it's loaded.
     * @param onError - Callback which is called if there's an error loading the mesh.
     * @returns The FaceTracker.
     */
    load(customModel, onLoad, onProgress, onError) {
        const trk = new defaultpipeline_1.FaceTracker();
        const p = customModel ? trk.loadModel(customModel) : trk.loadDefaultModel();
        const itemFilename = typeof customModel === "string" ? customModel : "__zappar_threejs_face_tracker_default";
        p.then(() => {
            onLoad === null || onLoad === void 0 ? void 0 : onLoad(trk);
            this.manager.itemEnd(itemFilename);
        }).catch((_) => {
            onError === null || onError === void 0 ? void 0 : onError(_);
            this.manager.itemError(itemFilename);
            this.manager.itemEnd(itemFilename);
        });
        return trk;
    }
    /**
     * @ignore
     */
    parse() { }
}
exports.FaceTrackerLoader = FaceTrackerLoader;
