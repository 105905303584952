"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LibraryLoader = void 0;
/* eslint-disable class-methods-use-this */
const __1 = require("..");
const three_1 = require("../three");
const itemFilename = "__zappar_threejs_library";
/**
 * Loader for library itself.
 * This library uses some additional resources (e.g web workers and WebAssembly) - this loader resolves once the library has fully loaded these resources.
 * If you're using the LoadingManager included in this library you don't need to explicitly use this yourself as one is automatically created.
 */
class LibraryLoader extends three_1.THREE.Loader {
    /**
     * Resolves once the library is loaded and ready to process data.
     * @param onLoad - Callback which runs once the library is fully loaded.
     * @param onError - Callback which is called if there's an error loading library.
     */
    load(onLoad, onProgress, onError) {
        this.manager.itemStart(itemFilename);
        (0, __1.loadedPromise)()
            .then(() => {
            onLoad === null || onLoad === void 0 ? void 0 : onLoad();
            this.manager.itemEnd(itemFilename);
        })
            .catch((err) => {
            onError === null || onError === void 0 ? void 0 : onError();
            this.manager.itemError(itemFilename);
            this.manager.itemEnd(itemFilename);
        });
    }
    /**
     * @ignore
     */
    parse() { }
}
exports.LibraryLoader = LibraryLoader;
