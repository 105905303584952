"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.THREE = void 0;
/* eslint-disable tsdoc/syntax */
// eslint-disable-next-line no-restricted-imports
const three = require("three");
/**
 * THREE import which gets converted to AFRAME.THREE for @zappar/three-for-aframe.
 */
exports.THREE = three;
