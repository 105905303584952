"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaceAnchorGroup = void 0;
const three_1 = require("../three");
const camera_1 = require("../camera");
/**
 * A THREE.Group which attaches content to a face as it moves around in the camera view.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/face-tracking/
 */
class FaceAnchorGroup extends three_1.THREE.Group {
    /**
     * Constructs a new FaceAnchorGroup.
     * @param camera - A ZapparThree.Camera.
     * @param faceTracker - The face tracker which will be used.
     * @param anchorId - Specify this to limit the group to tracking an anchor with the provided ID.
     */
    constructor(camera, faceTracker, anchorId) {
        super();
        this.camera = camera;
        this.faceTracker = faceTracker;
        this.anchorId = anchorId;
        /**
         * @ignore
         */
        this.isReady = false;
        // We'll be updating this Group's matrix ourselves from the Zappar library
        this.matrixAutoUpdate = false;
    }
    updateMatrixWorld(force) {
        if (!this.currentAnchor || !this.faceTracker.visible.has(this.currentAnchor)) {
            // No current anchor, or current anchor isn't visible
            if (this.anchorId) {
                this.currentAnchor = this.faceTracker.anchors.get(this.anchorId);
            }
            else {
                this.currentAnchor = this.faceTracker.visible.values().next().value;
            }
        }
        if (this.currentAnchor) {
            this.matrix.fromArray(this.currentAnchor.pose(this.camera.rawPose, this.camera.currentMirrorMode === camera_1.CameraMirrorMode.Poses));
            this.matrix.decompose(this.position, this.quaternion, this.scale);
        }
        super.updateMatrixWorld(force);
    }
}
exports.FaceAnchorGroup = FaceAnchorGroup;
