"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeadMaskMeshLoader = void 0;
/* eslint-disable class-methods-use-this */
const three_1 = require("../three");
const headmaskmesh_1 = require("../mesh/headmaskmesh");
const itemFilename = "__zappar_threejs_head_mask_mesh";
/**
 * Loader for HeadMaskMesh objects.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/face-tracking/
 */
class HeadMaskMeshLoader extends three_1.THREE.Loader {
    /**
     * Loads a HeadMaskMesh.
     * @param onLoad - Callback which returns the HeadMaskMesh once it's loaded.
     * @param onError - Callback which is called if there's an error loading the mesh.
     * @returns The HeadMaskMesh.
     */
    load(onLoad, onProgress, onError) {
        this.manager.itemStart(itemFilename);
        return new headmaskmesh_1.HeadMaskMesh(() => {
            onLoad === null || onLoad === void 0 ? void 0 : onLoad();
            this.manager.itemEnd(itemFilename);
        }, () => {
            onError === null || onError === void 0 ? void 0 : onError();
            this.manager.itemError(itemFilename);
            this.manager.itemEnd(itemFilename);
        });
    }
    /**
     * @ignore
     */
    parse() { }
}
exports.HeadMaskMeshLoader = HeadMaskMeshLoader;
