"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Camera = exports.CameraMirrorMode = exports.CameraPoseMode = void 0;
const Zappar = require("@zappar/zappar");
const three_1 = require("./three");
const defaultpipeline_1 = require("./defaultpipeline");
const cameraTexture_1 = require("./cameraTexture");
/**
 * The pose modes that determine how the camera moves around in the scene.
 */
var CameraPoseMode;
(function (CameraPoseMode) {
    /**
     * The camera sits, stationary, at the origin of world space, and points down the negative Z axis.
     * In this mode, tracked anchors move in world space as the user moves the device or tracked objects in the real world.
     */
    CameraPoseMode[CameraPoseMode["Default"] = 0] = "Default";
    /**
     * The camera sits at the origin of world space, but rotates as the user rotates the physical device.
     *
     * When the Zappar library initializes, the negative Z axis of world space points forward in front of the user.
     *
     * In this mode, tracked anchors move in world space as the user moves the device or tracked objects in the real world.
     */
    CameraPoseMode[CameraPoseMode["Attitude"] = 1] = "Attitude";
    /**
     * In this case the camera moves and rotates in world space around the anchor at the origin.
     */
    CameraPoseMode[CameraPoseMode["AnchorOrigin"] = 2] = "AnchorOrigin";
})(CameraPoseMode = exports.CameraPoseMode || (exports.CameraPoseMode = {}));
/**
 * The mirror modes that may be used.
 */
var CameraMirrorMode;
(function (CameraMirrorMode) {
    /**
     * No mirroring.
     */
    CameraMirrorMode[CameraMirrorMode["None"] = 0] = "None";
    /**
     * This mode mirrors the background camera texture and ensures content still appears correctly tracked.
     * In this mode your content itself isn't flipped, so any text in your tracked content doesn't appear mirrored.
     * This is the default mode for the user-facing camera.
     */
    CameraMirrorMode[CameraMirrorMode["Poses"] = 1] = "Poses";
    /**
     * In this mode, the Zappar camera applies a scaleX(-1) CSS transform to your whole canvas.
     * This way both the camera and your content appear mirrored.
     */
    CameraMirrorMode[CameraMirrorMode["CSS"] = 2] = "CSS";
})(CameraMirrorMode = exports.CameraMirrorMode || (exports.CameraMirrorMode = {}));
/**
 * The directions that the camera can face.
 */
var CameraDirection;
(function (CameraDirection) {
    /** The camera is not running.
     * This is the default mode for the camera.
     */
    CameraDirection[CameraDirection["None"] = 0] = "None";
    /** In this mode, the camera is always facing the user. */
    CameraDirection[CameraDirection["User"] = 1] = "User";
    /** In this mode, the camera is always facing away from the user. */
    CameraDirection[CameraDirection["Rear"] = 2] = "Rear";
})(CameraDirection || (CameraDirection = {}));
/**
 * Creates a camera that you can use instead of a perspective camera.
 *
 * The camera provides a {@link Camera.backgroundTexture} property containing the camera feed.
 *
 * The ZapparThree library needs to use your WebGL context in order to process camera frames.
 * You can set it when your page loads using {@link glContextSet}.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/camera-setup/
 */
class Camera extends three_1.THREE.Camera {
    /**
     * Constructs a new Camera.
     * @param pipeline - The pipeline that this tracker will operate within.
     * @property pipeline - The pipeline that this tracker will operate within.
     * @property zNear - The near clipping plane.
     * @property zFar - The far clipping plane.
     * @property rearCameraSource? - The camera source which will be used for the rear camera.
     * @property userCameraSource? - The camera source which will be used for the user camera.
     */
    constructor(opts) {
        super();
        /**
         * The pose mode that determines how the camera moves in the scene.
         */
        this.poseMode = CameraPoseMode.Default;
        /**
         * The mirror mode that is used for the rear camera.
         */
        this.rearCameraMirrorMode = CameraMirrorMode.None;
        /**
         * The mirror mode that is used for the user camera.
         */
        this.userCameraMirrorMode = CameraMirrorMode.Poses;
        this._currentMirrorMode = CameraMirrorMode.None;
        /**
         * @ignore
         * Needed for raycasters to work.
         */
        this.isPerspectiveCamera = true;
        this.cameraDirection = CameraDirection.None;
        this.hasSetCSSScaleX = false;
        this.renderWidth = 0;
        this.renderHeight = 0;
        this.pipeline = opts instanceof Zappar.Pipeline ? opts : (opts === null || opts === void 0 ? void 0 : opts.pipeline) || (0, defaultpipeline_1.getDefaultPipeline)();
        this.rawPose = this.pipeline.cameraPoseDefault();
        if (opts && !(opts instanceof Zappar.Pipeline)) {
            this.zNear = opts.zNear ? opts.zNear : 0.1;
            this.zFar = opts.zFar ? opts.zFar : 100;
            this.rearCameraSource = this.cameraSourceFromOpts(opts.rearCameraSource);
            this.userCameraSource = this.cameraSourceFromOpts(opts.userCameraSource, true);
            this.backgroundTexture = opts.backgroundTexture ? opts.backgroundTexture : new cameraTexture_1.CameraTexture();
        }
        else {
            this.rearCameraSource = new defaultpipeline_1.CameraSource(Zappar.cameraDefaultDeviceID(), this.pipeline);
            this.userCameraSource = new defaultpipeline_1.CameraSource(Zappar.cameraDefaultDeviceID(true), this.pipeline);
            this.backgroundTexture = new cameraTexture_1.CameraTexture();
        }
        this.matrixAutoUpdate = false;
        document.addEventListener("visibilitychange", () => {
            document.visibilityState === "visible" ? this.resume() : this.pause();
        });
    }
    /**
     * Constructs a new CameraSource or HTMLElementSource based on parameters passed in.
     * @param cameraSource - HTML element or camera device ID which will be used as a source
     * @returns CameraSource if cameraSource param is undefined or string, otherwise HTMLElementSource.
     */
    cameraSourceFromOpts(cameraSource, frontFacing = false) {
        return cameraSource instanceof Element
            ? new Zappar.HTMLElementSource(this.pipeline, cameraSource)
            : new defaultpipeline_1.CameraSource(cameraSource || Zappar.cameraDefaultDeviceID(frontFacing), this.pipeline);
    }
    /**
     * Pauses the camera source.
     */
    pause() {
        this.userCameraSource.pause();
        this.rearCameraSource.pause();
    }
    /**
     * Starts the camera source.
     *
     * Starting a given source pauses any other sources within the same pipeline.
     */
    resume() {
        switch (this.cameraDirection) {
            case CameraDirection.User:
                this.userCameraSource.start();
                break;
            case CameraDirection.Rear:
                this.rearCameraSource.start();
                break;
            default:
                // do not start any camera
                break;
        }
    }
    /**
     * Starts the camera source.
     * @param userFacing - If true, starts the user facing camera. (i.e selfie).
     */
    start(userFacing) {
        this.cameraDirection = userFacing ? CameraDirection.User : CameraDirection.Rear;
        this.resume();
    }
    /**
     * Stops the camera source.
     */
    stop() {
        this.cameraDirection = CameraDirection.None;
        this.pause();
    }
    /**
     * Sets the pose mode to 'Anchor Origin'.
     *
     * In this case the camera moves and rotates in world space around the anchor at the origin.
     * @param anchor - The anchor that defines the origin.
     */
    setPoseModeAnchorOrigin(anchor) {
        this.poseAnchorOrigin = anchor;
        this.poseMode = CameraPoseMode.AnchorOrigin;
    }
    /**
     * Gets the current mirror mode.
     */
    get currentMirrorMode() {
        // eslint-disable-next-line no-underscore-dangle
        return this._currentMirrorMode;
    }
    /**
     * Processes camera frames and updates `backgroundTexture`.
     * Call this function on your pipeline once an animation frame (e.g. during your `requestAnimationFrame` function).
     * @param renderer - The Three.js WebGL renderer.
     */
    updateFrame(renderer) {
        this.pipeline.processGL();
        // Update to using the latest tracking frame data
        this.pipeline.frameUpdate();
        // eslint-disable-next-line no-underscore-dangle
        this._currentMirrorMode = this.pipeline.cameraFrameUserFacing() ? this.userCameraMirrorMode : this.rearCameraMirrorMode;
        const { domElement } = renderer;
        if (this.currentMirrorMode !== CameraMirrorMode.CSS && this.hasSetCSSScaleX) {
            domElement.style.transform = "";
            this.hasSetCSSScaleX = false;
        }
        else if (this.currentMirrorMode === CameraMirrorMode.CSS && !this.hasSetCSSScaleX) {
            domElement.style.transform = "scaleX(-1)";
            this.hasSetCSSScaleX = true;
        }
        this.renderWidth = renderer.domElement.width;
        this.renderHeight = renderer.domElement.height;
        // eslint-disable-next-line no-underscore-dangle
        this._updateProjectionMatrix();
        // Get the pose of the camera from the Zappar library
        switch (this.poseMode) {
            case CameraPoseMode.Default:
                this.rawPose = this.pipeline.cameraPoseDefault();
                break;
            case CameraPoseMode.Attitude:
                this.rawPose = this.pipeline.cameraPoseWithAttitude(this.currentMirrorMode === CameraMirrorMode.Poses);
                break;
            case CameraPoseMode.AnchorOrigin:
                this.rawPose = this.poseAnchorOrigin ? this.getOriginPose() : this.pipeline.cameraPoseDefault();
                break;
            default:
                this.rawPose = this.pipeline.cameraPoseDefault();
                break;
        }
        this.matrixWorldNeedsUpdate = true;
        this.backgroundTexture.MirrorMode = this.currentMirrorMode;
        this.backgroundTexture.updateFromPipeline(renderer, this.pipeline);
    }
    // eslint-disable-next-line no-underscore-dangle
    _updateProjectionMatrix() {
        // Get the projection matrix for the camera from the Zappar library
        const model = this.pipeline.cameraModel();
        const projection = Zappar.projectionMatrixFromCameraModel(model, this.renderWidth, this.renderHeight, this.zNear, this.zFar);
        this.projectionMatrix.fromArray(projection);
        if (typeof this.projectionMatrixInverse.invert === "function") {
            this.projectionMatrixInverse.copy(this.projectionMatrix).invert();
        }
        else {
            this.projectionMatrixInverse.getInverse(this.projectionMatrix);
        }
    }
    updateMatrixWorld(force) {
        this.matrix.fromArray(this.rawPose);
        this.matrix.decompose(this.position, this.quaternion, this.scale);
        super.updateMatrixWorld(force);
    }
    getOriginPose() {
        if (!this.poseAnchorOrigin)
            return this.pipeline.cameraPoseDefault();
        return this.pipeline.cameraPoseWithOrigin(this.poseAnchorOrigin.poseCameraRelative(this.currentMirrorMode === CameraMirrorMode.Poses));
    }
    /**
     * Destroys the camera sources.
     */
    dispose() {
        this.rearCameraSource.destroy();
        this.userCameraSource.destroy();
    }
}
exports.Camera = Camera;
